import "../styles/main.scss";

class UI {
    /**
     * Put your code for jquery $(document).ready() function
     */
    ready() {
        let $body = $("body");

        //modal open fix - add class modal open to make sure that the ipad don't lose focus
        $(document).on('hidden.bs.modal', function () {
          if ($('.modal:visible').length) {
            $body.addClass('modal-open');
          }
        });
    }

    /**
     * Put your code for jquery $(window).load() function
     */
    loaded() {
        /* animate .form-confirmation on load */
        if($(".form-confirmation").length) {
            $(".form-confirmation").addClass("loaded");
        }
    }
}

export default new UI();